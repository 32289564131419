import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'
import Vant from 'vant';
import { PullRefresh } from 'vant';
import 'vant/lib/index.css'; 
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import VConsole from 'vconsole';
import 'babel-polyfill'
import Es6Promise from 'es6-promise'
require('es6-promise').polyfill()
Es6Promise.polyfill()


// if(process.env.NODE_ENV !== "production"){
    // const vConsole = new VConsole({ theme: 'dark' });
// }

var vm= createApp(App).use(Vant).use(ElementPlus).use(PullRefresh).use(store).use(router).mount('#app')
 