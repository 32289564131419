import axios from "axios";
import { Notify } from "vant";
import axiosRetry from "axios-retry";

// 获取环境变量
export const getBaseURI = () => {
  let env = process.env.VUE_APP_NODE_ENV || process.env.NODE_ENV;

  //    开发环境
  if (env === "dev" || env == "development") {
    //    return 'http://112.30.213.162:8082/stayhome-app'
    //return 'http://192.168.1.101:9013/stayhome-app';
    // return 'https://s-stg.ringzle.com/stayhome-app'
    //    return 'http://192.168.1.102:9013/stayhome-app';
    return "https://s.ringzle.com/stayhome-app";
  }

  // 集成测试环境
  if (env === "prod:sit") {
    return "https://s.ringzle.com/stayhome-app";
  }

  if (env === "prod:uat") {
    return "https://s.ringzle.com/stayhome-app";
  }
  // 生产环境
  if (env === "prod" || env === "production") {
    return "/stayhome-app";
  }
};

export const BaseUrl = getBaseURI();

export const service = axios.create({
  timeout: 10000,
  timeoutErrorMessage: "请求超时，请检查网络后重试",
  baseURL: getBaseURI(),
});

/**
 * req 拦截器
 */
service.interceptors.request.use(
  (config) => {
    // 配置头部
    let token = window.localStorage.getItem("token");
    if (token != "") {
      config.headers["token"] = token;
    }
    return config;
  },
  (error) => {
    Notify({ type: "danger", message: JSON.stringify(error) });
    return Promise.resolve(error);
  }
);

/**
 * res 拦截器
 */
service.interceptors.response.use((response) => {
  const res = response.data;
  // 判断token 失效
  if (res.code == 10021) {
    Notify({ type: "danger", message: res.msg });
  }
  if (res.code == 500) {
    Notify({ type: "danger", message: res.msg });
  }
  if (res.error) {
    if (process.env.NODE_ENV !== "production") {
      console.error(res);
    }
    Notify({ type: "danger", message: JSON.stringify(res) });
    return Promise.resolve(res);
  }
  return Promise.resolve(res);
});

axiosRetry(service, {
  retries: 3, // 设置自动发送请求次数
  retryDelay: () => 300, // 重新请求的间隔
  shouldResetTimeout: true, //  重置超时时间
  retryCondition: (error) => {
    // true为打开自动发送请求，false为关闭自动发送请求
    if (error.message.includes("timeout")) return true;
    // 如果你要在请求出错的时候重新发送请求（返回400以上的状态码时） 你应该像下面这样写
    if (
      error.message.includes("timeout") ||
      error.message.includes("status code")
    )
      return true;
  },
});
