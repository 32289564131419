<template>
  <router-view
    v-slot="slotProps"
    v-on:setActive="setActive"
  >
    <keep-alive>
      <component :is="slotProps.Component"></component>
    </keep-alive>
  </router-view>
  <!-- <div style="height: 30px"></div> -->
  <div
    v-if="NoPermission"
    style="color: red; margin: 15px; font-size: 16px"
  >
    对不起，您的账号不属于（网格员、转运管理员、转运专员、事务局领导）中的一种 <br />
    请联系平台管理员关闭入口按钮权限，或联系重点人群管理平台管理员授权适当的权限
  </div>
  <!-- <router-view @changeTitle="changeTitle($event)" /> -->
  <van-popup
    v-model:show="isRolePickShow"
    round
    position="bottom"
  >
    <van-picker
      title="角色切换"
      :columns="columnsFilter"
      :columns-field-names="{ text: 'name', value: 'value' }"
      @confirm="onRoleConfirm"
      @cancel="isRolePickShow = false"
      @change="roleChange"
    />
  </van-popup>

  <!-- 网格人员 v-if  用户类型== 网格人员 -->
  <van-tabbar
    v-model="dataForm.active"
    @change="menuChange"
    :placeholder="true"
    v-if="dataForm.role == 'GridPerson'&&isShowMenu=='true'&&1===2"
    fixed="true"
  >
    <van-tabbar-item
      name="alllist"
      replace
      icon="friends-o"
      to="/allList"
    >管控人员</van-tabbar-item>
    <van-tabbar-item
      name="todolist"
      replace
      icon="todo-list-o"
      to="/todolist"
    >工作填报</van-tabbar-item>
    <van-tabbar-item
      name="addPersonInfo"
      replace
      icon="todo-list-o"
      to="/addPersonInfo"
    >主动上报</van-tabbar-item>
    <van-tabbar-item
      name="changeRole"
      icon="todo-list-o"
      v-if="dataForm.roleList.length > 1"
      @click="isRolePickShow = true"
    >切换角色</van-tabbar-item>
  </van-tabbar>
  <!-- 网格人员 v-if  用户类型== 网格人员 -->
  <van-cell-group
    inset
    title="我要:"
    border
    class="gridMenu"
    v-if="(dataForm.role == 'GridPerson'||dataForm.role=='FiftyPerson')&&$router.currentRoute.value.path=='/blank'"
  >
  <template v-if="dataForm.role=='GridPerson'">
    <van-cell
      title-style="text-align:left;font-size:21px;flex: auto;"
      title="上报新人员"
      value=""
      is-link
      to="addPersonInfo"
    />
    <!-- <van-cell
      title-style="text-align:left;font-size:21px;flex: auto;"
      title="上报50类人群"
      value=""
      is-link
      to="addPersonInfo28"
    /> -->
    <van-cell
      v-if="dataForm.userInfo.roleCodes.indexOf('SysAdmin') <0"
      title-style="text-align:left;font-size:21px;flex: auto;"
      value-class="gridCountTip"
      title="未读人员信息"
      :value="gridPersonDoCount.hcCount"
      is-link
      :to="{ name: '工作填报', params: { type: '待核查' } }"
    />
    <van-cell
      title-style="text-align:left;font-size:21px;flex: auto;"
      value-class="gridCountTip"
      title="创建健康填报"
      :value="gridPersonDoCount.tbCount"
      is-link
      :to="{ name: '工作填报', params: { type: '每日上报',personType:'' } }"
    />
    <van-cell
      title-style="text-align:left;font-size:21px;flex: auto;"
      value-class="gridCountTip"
      title="待审核人员"
      is-link
      :value="gridPersonDoCount.dshCount"
      :to="{path: 'swjApproveList'}"
    />
    <van-cell
      title-style="text-align:left;font-size:21px;flex: auto;"
      value-class="gridCountTip"
      title="查看转运单"
      is-link
      :to="{ name: '工作填报', params: { type: '转运执行' } }"
    />
    <van-cell
      title-style="text-align:left;font-size:21px;flex: auto;"
      value-class="gridCountTip"
      title="查看门磁预警人员"
      :value="gridPersonDoCount.mcCount"
      is-link
      :to="{ name: '工作填报', params: { type: '门磁监控' } }"
    />
    <van-cell
      title-style="text-align:left;font-size:21px;flex: auto;"
      value-class="gridCountTip"
      title="查看所有管控人员"
      value=""
      is-link
      to="alllist"
    />
    <van-cell
      title-style="text-align:left;font-size:21px;flex: auto;"
      value-class="gridCountTip"
      title="查看50类人员"
      value=""
      is-link
      :to="{path:'alllist28',query:{type: 'TwentyEight'}}"
    />
    <van-cell
      title-style="text-align:left;font-size:21px;flex: auto;"
      value-class="gridCountTip"
      title="切换角色"
      value=""
      v-if="dataForm.roleList.length > 1"
      @click="isRolePickShow = true"
      is-link
    />
  </template>
  <template v-if="dataForm.role=='FiftyPerson'">
     <van-cell
      title-style="text-align:left;font-size:21px;flex: auto;"
      title="上报50类人群"
      value=""
      is-link
      to="addPersonInfo28"
    />
     <van-cell
      title-style="text-align:left;font-size:21px;flex: auto;"
      value-class="gridCountTip"
      title="查看50类人员"
      value=""
      is-link
      :to="{path:'alllist28',query:{type: 'TwentyEight'}}"
    />
     <van-cell
      title-style="text-align:left;font-size:21px;flex: auto;"
      value-class="gridCountTip"
      title="切换角色"
      value=""
      v-if="dataForm.roleList.length > 1"
      @click="isRolePickShow = true"
      is-link
    />
  </template>
  </van-cell-group>

  <!-- 转运管理员 v-if  用户类型== 转运管理员 -->
  <van-tabbar
    v-model="dataForm.active"
    @change="menuChange"
    :placeholder="true"
    fixed
    v-if="dataForm.role == 'TransferManager'&&isShowMenu=='true'"
  >
    <van-tabbar-item
      name="transitManager"
      icon="friends-o"
      replace
      to="/transitManager"
    >转运管理</van-tabbar-item>
    <van-tabbar-item
      name="reportTransit"
      icon="chart-trending-o"
      replace
      to="/reportTransit"
    >转运报表</van-tabbar-item>
    <van-tabbar-item
      name="alllist"
      icon="home-o"
      replace
      to="/alllist"
    >全部人员</van-tabbar-item>
    <van-tabbar-item
      name="changeRole"
      icon="todo-list-o"
      v-if="dataForm.roleList.length > 1"
      @click="isRolePickShow = true"
    >切换角色</van-tabbar-item>
  </van-tabbar>

  <!-- 事务局领导 v-if  用户类型== 事务局领导  -->
  <van-tabbar
    v-model="dataForm.active"
    @change="menuChange"
    :placeholder="true"
    fixed
    v-if="dataForm.role == 'Leader'&&isShowMenu=='true'"
  >
    <van-tabbar-item
      name="reportPersonType"
      icon="chart-trending-o"
      replace
      to="/reportPersonType"
    >统计报表
    </van-tabbar-item>
    <van-tabbar-item
      name="reportTransit"
      icon="chart-trending-o"
      replace
      to="/reportTransit"
    >转运报表</van-tabbar-item>
    <van-tabbar-item
      name="alllist"
      icon="home-o"
      replace
      to="/alllist"
    >全部人员</van-tabbar-item>
    <van-tabbar-item
      name="changeRole"
      icon="todo-list-o"
      v-if="dataForm.roleList.length > 1"
      @click="isRolePickShow = true"
    >切换角色</van-tabbar-item>
  </van-tabbar>

  <!-- 事务管理员 v-if  用户类型== 事务局管理员    v-if="dataForm.role == 'SwjAdmin'" -->
  <van-tabbar
    v-model="dataForm.active2"
    :placeholder="true"
    fixed
    v-if="dataForm.role == 'SwjAdmin'&&isShowMenu=='true'"
  >
    <van-tabbar-item
      name="swjApproveList"
      icon="friends-o"
      replace
      to="/swjApproveList"
    >待审核人员
    </van-tabbar-item>
    
    <van-tabbar-item
      name="swjAllList"
      icon="home-o"
      replace
      to="/swjAllList"
    >全部人员</van-tabbar-item>
    <van-tabbar-item
      name="changeRole"
      icon="todo-list-o"
      v-if="dataForm.roleList.length > 1"
      @click="isRolePickShow = true"
    >切换角色</van-tabbar-item>
  </van-tabbar>

  <!-- 转运专员 v-if  用户类型== 事务局管理员    v-if="dataForm.role == 'SwjAdmin'" -->
  <van-tabbar
    v-model="dataForm.active"
    @change="menuChange"
    :placeholder="true"
    fixed
    v-if="dataForm.role == 'Transfer' &&isShowMenu=='true'"
  >
    <van-tabbar-item
      name="Transfer"
      icon="friends-o"
      replace
      to="/transitList"
    >转运专员
    </van-tabbar-item>

    <van-tabbar-item
      name="changeRole"
      icon="todo-list-o"
      v-if="dataForm.roleList.length > 1"
      @click="isRolePickShow = true"
    >切换角色</van-tabbar-item>
  </van-tabbar>
 <!--  数据变更管理员 v-if  用户类型==  数据变更管理员    -->
  <van-tabbar
    v-model="dataForm.active"
    @change="menuChange"
    :placeholder="true"
    fixed
    v-if="dataForm.role == 'DataChangeAdmin' &&isShowMenu=='true'"
  >
   <van-tabbar-item
      name="ApproveList"
      icon="records"
      replace
      to="/ApproveList"
    >审核变更
    </van-tabbar-item>

    <van-tabbar-item
      name="changeRole"
      icon="todo-list-o"
      v-if="dataForm.roleList.length > 1"
      @click="isRolePickShow = true"
    >切换角色</van-tabbar-item>
  </van-tabbar>
  <!-- <div style="height: 30px"></div> -->
  <!-- 开启底部安全区适配 -->
  <van-number-keyboard safe-area-inset-bottom />
</template>
<script>
import { ref, reactive } from "vue";
import { useRouter } from "vue-router";
import Api from "./util/App";
import { Notify, Toast } from "vant";
export default {
  setup() {
    const dataForm = reactive({
      active: ref("alllist"),
      active2:ref('swjApproveList'),
      leftArrow: false,
      pageTitle: "居家隔离人员管控",
      navTitle: "",
      currentRole: "",
      role: "",
      roleList: [],
      userInfo: { roleCodes: [] },
    });

    const changeTitle = (name) => {
      dataForm.navTitle = router.currentRoute.value.name;
    };

    const router = useRouter();
    const getUrlKey = (name) => {
      // eslint-disable-next-line no-sparse-arrays
      return (
        decodeURIComponent(
          // eslint-disable-next-line no-sparse-arrays
          (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(location.href) || [, ""])[1].replace(/\+/g, "%20")) || null);
    };

    const NoPermission = ref(false);
    const isSysAdmin = ref(false);
    const isRolePickShow = ref(false);

    const columns = [
      { name: "网格长", value: "GridPerson" },
      { name: "转运管理员", value: "TransferManager" },
      { name: "转运专员", value: "Transfer" },
      { name: "事务局领导", value: "Leader" },
      { name: "事务局管理员", value: "SwjAdmin" },
      { name: " 数据变更管理员", value: "DataChangeAdmin" },
      { name: "50类人群管理员", value: "FiftyPerson" },
    ];
    const columnsFilter = [];
    const isShowMenu = ref(false);
    const gridPersonDoCount = ref({
      hcCount: 0,
      tbCount: 0,
      tbCount28: 0,
      zyCount: 0,
      mcCount: 0,
      dshCount: 0,
    });
    return {
      dataForm,
      changeTitle,
      router,
      getUrlKey,
      NoPermission,
      isSysAdmin,
      isRolePickShow,
      columns,
      columnsFilter,
      isShowMenu,
      gridPersonDoCount,
    };
  },
  watch: {
    $route(val) {
      if (val.path == "/blank") {
        this.getPersonCount();
      }
    },
  },
  mounted() {
    this.isShowMenu = window.localStorage.getItem("isFirst");
    setInterval(this.getIsFirst, 500);
    if (location.href.indexOf("/login") >= 0) {
      window.localStorage.setItem("token", "");
      window.localStorage.setItem("role", "");
      this.dataForm.role = "";
    }

    this.NoPermission = false;
    let sourceToken = this.getUrlKey("token");
    let phone = this.getUrlKey("phone");
    let code = this.getUrlKey("code");
    if (sourceToken) {
      this.login(sourceToken);
    } else if (phone && code) {
      this.LocalHost(phone, code);
    } else if (window.localStorage.getItem("token")) {
      this.getUserinfo();
    }
    // 加载所有字典数据
    Api.getDictList().then((res) => {
      this.$store.state.Dict = res.data;
    });
  },
  activated() {
    if (location.href.indexOf("/login") >= 0) {
      window.localStorage.setItem("token", "");
      window.localStorage.setItem("role", "");
      this.dataForm.role = "";
    }

    let sourceToken = this.getUrlKey("token");
    let phone = this.getUrlKey("phone");
    let code = this.getUrlKey("code");
    if (sourceToken) {
      this.login(sourceToken);
    } else if (phone && code) {
      this.LocalHost(phone, code);
    } else if (window.localStorage.getItem("token")) {
      this.getUserinfo();
    }
    // 加载所有字典数据
    Api.getDictList().then((res) => {
      this.$store.state.Dict = res.data;
    });
  },

  methods: {
    getIsFirst() {
      this.isShowMenu = window.localStorage.getItem("isFirst");
    },
    setActive(name) {
      this.dataForm.active = name;
    },
    menuChange(v){
      console.log(v)
    },
    login(token) {
      Api.getloginByToken(token).then((res) => {
        if (res.code == 0) {
          window.localStorage.setItem("token", res.data.token);
          this.getUserinfo();
        } else {
          this.NoPermission = true;
          Notify({ type: "danger", message: res.msg });
        }
      });
    },
    roleChange() {},
    onRoleConfirm(item, index) {
      window.localStorage.setItem("role", item.value);
      this.dataForm.role = item.value;
      switch (this.dataForm.role) {
        case "Leader":
          this.router.replace("reportPersonType");
          this.dataForm.active = "reportPersonType";
          break;
        case "TransferManager":
          this.router.replace("transitManager");
          this.dataForm.active = "transitManager";
          break;
        case "Transfer":
          this.router.replace("transitList");
          this.dataForm.active = "Transfer";
          break;
        case "GridPerson":
          this.router.replace("blank");
          break;
            case "FiftyPerson":
          this.router.replace("blank");
          break;
        case "SwjAdmin":
          this.router.replace("swjApproveList");
          this.dataForm.active = "swjApproveList";
          break;
         case "DataChangeAdmin":
          this.router.replace("ApproveList");
          this.dataForm.active = "ApproveList";
          break;
        default:
          break;
      }

      this.isRolePickShow = false;
    },

    LocalHost(phone, code) {
      Api.testLogin({
        captcha: "1",
        password: code,
        username: phone,
        uuid: "1",
      }).then((res) => {
        if (res.code == 0) {
          window.localStorage.setItem("token", res.data.token);
          this.getUserinfo();
        } else {
          this.NoPermission = true;
          Notify({ type: "danger", message: res.msg });
        }
      });
    },
    getUserinfo() {
      Api.userinfo().then((res) => {
        if (res.code == 0) {
          this.dataForm.userInfo = res.data;

          let roleAllList = [
            "GridPerson",
            "TransferManager",
            "Leader",
            "SwjAdmin",
            "Transfer",
            "DataChangeAdmin",
            "FiftyPerson",
          ];
          //非系统管理员下根据后端传值赋值角色
          // for (var i = 0; i < this.dataForm.userInfo.roleCodes.length; i++) {
          //   if (roleAllList.indexOf(this.dataForm.userInfo.roleCodes[i]) >= 0) {
          //     this.dataForm.roleList.push(this.dataForm.userInfo.roleCodes[i]);
          //   }
          // }

          // 如果是管理员 、
          if (this.dataForm.userInfo.roleCodes.indexOf("SysAdmin") >= 0) {
            
             if (this.dataForm.roleList.indexOf("Leader") == -1) {
              this.dataForm.roleList.push("Leader");
            }
            if (this.dataForm.roleList.indexOf("TransferManager") == -1) {
              this.dataForm.roleList.push("TransferManager");
            }
            if (this.dataForm.roleList.indexOf("GridPerson") == -1) {
              this.dataForm.roleList.push("GridPerson");
            }
            if (this.dataForm.roleList.indexOf("SwjAdmin") == -1) {
              this.dataForm.roleList.push("SwjAdmin");
            }

            if (this.dataForm.roleList.indexOf("Transfer") == -1) {
              this.dataForm.roleList.push("Transfer");
            }
            if (this.dataForm.roleList.indexOf("DataChangeAdmin") == -1) {
              this.dataForm.roleList.push("DataChangeAdmin");
            }
             if (this.dataForm.roleList.indexOf("FiftyPerson") == -1) {
              this.dataForm.roleList.push("FiftyPerson");
            }
          }else{
            for (var i = 0; i < this.dataForm.userInfo.roleCodes.length; i++) {
              if (roleAllList.indexOf(this.dataForm.userInfo.roleCodes[i]) >= 0) {
                this.dataForm.roleList.push(this.dataForm.userInfo.roleCodes[i]);
              }
            }
          }
          // 根据 roleList  设置菜单数据源

          this.columnsFilter = [];
          this.columns.forEach((p) => {
            if (this.dataForm.roleList.indexOf(p.value) >= 0) {
              this.columnsFilter.push(p);
            }
          });

          if (this.dataForm.roleList.length > 0) {
            this.dataForm.role = this.dataForm.roleList[0];
            window.localStorage.setItem("role", this.dataForm.role);
            let isJumpPage = this.getUrlKey("isJumpPage");
            switch (this.dataForm.role) {
              case "Leader":
                if (!isJumpPage) {
                  this.router.replace("reportPersonType");
                  this.dataForm.active = "reportPersonType";
                }

                break;
              case "TransferManager":
                if (!isJumpPage) {
                  this.router.replace("transitManager");
                  this.dataForm.active = "transitManager";
                }
                break;
              case "GridPerson":
                if (!isJumpPage) {
                  this.router.replace("blank");
                }
                break;
              case "FiftyPerson":
                if (!isJumpPage) {
                  this.router.replace("blank");
                }
                break;
              case "Transfer":
                if (!isJumpPage) {
                  this.router.replace("transitList");
                  this.dataForm.active = "transitList";
                }
                break;
              case "SwjAdmin":
                if (!isJumpPage) {
                  this.router.replace("swjApproveList");
                  this.dataForm.active = "swjApproveList";
                }
                break;
              case "DataChangeAdmin":
                if (!isJumpPage) {
                  this.router.replace("ApproveList");
                  this.dataForm.active = "ApproveList";
                }
                break;
              default:
                break;
            }
          } else {
            this.NoPermission = true;
          }
        }
      });
    },
    getPersonCount() {
      Api.getPersonList({ limit: 1, listType: 2, page: 1, isRead: 0 ,isLeader:0}).then(
        (res) => {
          this.gridPersonDoCount.hcCount = res.data.total;
        }
      );
      Api.getvhpage({ limit: 1, dataType: 1, page: 1,isLeader:0 }).then((res) => {
        this.gridPersonDoCount.tbCount = res.data.total;
      });
      Api.getRecentList({ limit: 10, page: 1 }).then((res) => {
        this.gridPersonDoCount.mcCount = res.data.total;
      });
      // Api.getvhpage28({ limit: 10, page: 1, dataType: 1 }).then((res) => {
      //   this.gridPersonDoCount.tbCount28 = res.data.total;
      // });
      Api.swjPersonPageList({ limit: 10, page: 1, statusApp: 2 ,isLeader:0}).then(
        (res) => {
          this.gridPersonDoCount.dshCount = res.data.total;
        }
      );
    },
  },
};
</script>
<style>
#app {
  font-family: ”Microsoft YaHei”, Arial, Helvetica, sans-serif, ”宋体”;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #333333;
  background: #f8f8f8;
  height: 100vh;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
#app .van-cell-group__title {
  text-align: left;
  font-size: 16;

  font-size: 18px;
  font-weight: 600;
  color: #666666;
  line-height: 29px;
}
.gridCountTip {
  color: #fa5555ff !important;
}
.gridMenu {
  background: #f8f8f8ff !important;
}
.gridMenu .van-cell {
  margin: 8px 0 8px 0 !important;
  height: 44px;
}
.van-field__label{width: 98px!important;}
.gridMenu .van-cell {
}
</style>
