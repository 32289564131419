import { service } from "./request";

export default {
  // post请求
  postDataList: (params) => service.post("/xxxx", params),

  // get请求
  getDataList: (params) => service.get("/xxxx", { params }),

  //https://cx.ringzle.com/cxcanteen-api/login
  // post请求
  posttest: (params) => service.post("/app/login", params),

  /**
   * @description:  人口库人员详细信息
   * @params {*}   idCard 身份证号
   * @return {*}
   * */
  getOrgPersonBaseInfo: (params) =>
    service.get("/app/org/baseInfo/" + params, {}),

  /**
   * @description:  人口库获取社区信息
   * @params {*}
   * @return {*}
   * */
  getOrgCommunityList: (params) =>
    service.get("/app/org/communityList", { params: params }),

  /**
   * @description: 人口库获取小区信息
   * @params {*}   communityId 社区id
   * @params {*}   villageId 小区id
   * @return {*}
   * */
  getOrgLiveList: (params) =>
    service.get("/app/org/liveList", { params: params }),

  /**
   * @description: 人口库获取楼栋信息
   * @params {*}   liveId 小区id
   * @return {*}
   * */
  getOrgStoreList: (params) =>
    service.get("/app/org/storeList", { params: params }),

  /**
   * @description: 人口库获取房屋信息
   * @params {*}   limit 显示条数
   * @params {*}   liveId 小区id
   * @params {*}   page  页
   *  @params {*}  storeId 楼栋id
   * @return {*}
   * */
  getOrgPublicPage: (params) =>
    service.get("/app/org/publicPage", { params: params }),

  /**
   * @description: 获取所有人员信息
   * @params {*}   limit  page listType personName
   * @return {*}
   * */
  getPersonList: (params) => service.get("/app/msgpage", { params: params }),

  /**
   * @description: 获取50类人员信息
   * @params {*}   limit  page listType personName
   * @return {*}
   * */
  get28PersonList: (params) =>
    service.get("/app/personOther/page", { params: params }),
  /**
   * @description: 管控人员详细信息
   * @params {*}   personId 管控人员ID
   * @return {*}
   * */
  getPersoninfo: (params) => service.get("/app/msgdetail/" + params, {}),

  /**
   * @description:  人员信息上报
   * @params {*}    person Json add
   * @return {*}
   * */
  addPersonInfo: (params) => service.post("/app/msgadd", params),

  /**
   * @description:  28项人员信息上报
   * @params {*}    person Json add
   * @return {*}
   * */
  add28PersonInfo: (params) => service.post("/app/personOther/save", params),
  add28codeperson: (params) => service.post("/app/othersave", params),

  /**
   * @description:  28项人员信息修改
   * @params {*}    person Json update
   * @return {*}
   * */
  update28PersonInfo: (params) =>
    service.post("/app/personOther/modify", params),

  /**
   * @description:  人员信息调整 （改变管控状态、隔离时间）
   * @params {*}    person Json
   * @return {*}
   * */
  controlUpdate: (params) => service.post("/app/msgupdate", params),

  /**
   * @description:  网格员确认管控状态
   * @params {*}    person Json
   * @return {*}
   * */
  confirmStatus: (params) => service.post("/app/confirmStatus", params),

  /**
   * @description:  隔离人员核查
   * @params {*}    person Json
   * @return {*}
   * */
  PersonCheck: (params) => service.post("/app/check", params),

  /**
   * @description:  行政区域
   * @params {*}    person Json
   * @return {*}
   * */
  getRegionList: (params) => service.get("/app/tree", params),

  /**
   * @description:  隔离模式列表
   * @params {*}    person Json
   * @return {*}
   * */
  getControlModeList: (params) => service.get("/app/getList", params),

  /**
   * @description:  风险等级列表
   * @params {*}    person Json
   * @return {*}
   * */
  getRiskList: (params) => service.get("/app/riskList", params),
  /**
   * @description:  转运分页
   * @params {*}   limit 显示条数
   * @params {*}   personName 人员姓名
   * @params {*}   page  页
   *  @params {*}  status  转运状态:未转运NotTransshipped,转运中InTransit,Transshipped已转运
   * @return {*}
   * */
  gettspage: (params) => service.get("/app/tspage", { params: params }),

  /**
   * @description:  转运详情
   * @params {*}   id
   * @return {*}
   * */
  gettsDetail: (params) => service.get("/app/tsdetail/" + params, {}),

  /**
   * @description:  新增转运
   * @params {*}    json
   * @return {*}
   **/
  gettsAdd: (params) => service.post("/app/tsadd", params),

  /**
   * @description:  开始转运
   * @params {*}    json
   * @return {*}
   **/
  gettsconfirm: (params) => service.post("/app/tsconfirm/" + params, {}),

  /**
   * @description:  结束转运
   * @params {*}    json
   * @return {*}
   **/
  gettsUpdate: (params) => service.post("/app/tsupdate", params),

  /**
   * @description:  填报任务分页
   * @params {*}   limit 显示条数
   * @params {*}   personName 人员姓名
   * @params {*}   page  页
   *  @params {*}  dataType  数据:1当日信息；2全部
   * @return {*}
   * */
  getvhpage: (params) => service.get("/app/vhpage", { params: params }),
  getvhpage28: (params) =>
    service.get("/app/personOther/vhpage", { params: params }),
  deldata: (params) => service.delete("/app/personOther", { data: params }),
  /**
   * @description:  填报任务详情
   * @params {*}   Id
   * @return {*}
   * */
  getvhDetail: (params) => service.get("/app/vhdetail/" + params, {}),

  /**
   * @description:  网格员任务填报
   * @params {*}    json
   * @return {*}
   **/
  getSubmit: (params) => service.post("/app/submit", params),

  /**
   * @description:  获取数据字典
   * @return {*}
   * */
  getDictList: (params) => service.get("/app/all", {}),

  /**
   * @description:  获取司机接口
   * @return {*}
   **/
  getDriverList: (params) => service.get("/app/drlist", {}),

  /**
   * @description:  获取测试token
   * @return {*}
   **/
  getAuthToken: (params) => service.get("/app/getAuthToken", {}),

  /**
   * @description:  登录
   * @params {*}    json
   * @return {*}
   **/
  getloginByToken: (params) => service.post("/app/loginByToken/" + params, {}),

  /**
   * @description:  查询门磁记录
   * @params {*}   imei 设备序列号
   * @return {*}
   * */
  getSensorList: (params) =>
    service.get("/app/getSensorList?imei=" + params, {}),

  /**
   * @description:  查询门磁记录 分页
   * @params {*}  limit	每页显示记录数	query	true
   * @params {*} page	当前页码，从1开始	query	true
   * @params {*} integer(int32)
   * @params {*}imei	门磁编号	query	false
   * @params {*}order	排序方式，可选值(asc、desc)	query	false
   * @params {*}orderField	排序字段	query	false
   * @params {*} personId	人员id	query	false
   * @return {*}
   * */
  getSensorPageList: (params) =>
    service.get("/app/pageList", { params: params }),

  /**
   * @description:  最近预警列表
   * @params {*}   limit 显示条数
   * @params {*}   keyword 姓名/身份证
   * @params {*}   page  页
   *  @params {*}  order 排序方式，可选值(asc、desc)
   *  @params {*}  orderField 排序字段
   * @return {*}
   * */
  getRecentList: (params) =>
    service.get("/app/getRecentList", { params: params }),

  /**
   * @description:  管理人员统计报表
   *  @params {*}   personType 人员类型
   * @return {*}
   * */
  controlPrsonnelTj: (params) =>
    service.get("/app/controlPrsonnelTj", { params: params }),

  /**
   * @description:  按人员类型进行统计报表
   * @return {*}
   * */
  contactReportByPersonType: (params) =>
    service.get("/app/contactReportByPersonType", { params: params }),

  /**
   * @description:  上传图片
   * @return {*}
   * */
  uploadFile: (params) =>
    service.post("/app/uploadFile", params, {
      header: { "Content-Type": "multipart/form-data" },
    }),

  testLogin: (params) => service.post("/app/login", params),

  userinfo: (params) => service.get("/app/userInfo"),

  /**
   * @description:   转运管理员指派司机
   * @params {*}    destination	转运目的地
   * @params {*}    driverId	转运司机id
   * @params {*}    transportIds	转运单ids
   * @return {*}
   **/
  transitOrder: (params) => service.post("/app/order/", params),

  /**
   * @description:   转运管理员指派司机
   * @params {*}    destination	转运目的地
   * @params {*}    driverId	转运司机id
   * @params {*}    transportIds	转运单ids
   * @return {*}
   **/
  getTransportinfoList: (params) =>
    service.get("/app/transportinfoTj", { params: params }),

  /**
   * @description:   网格员申请变更管控信息
   * @params {*}     json
   * @return {*}
   **/
  submitApprove: (params) => service.post("/app/datachange/", params),

  /**
   * @description:  查询门磁记录 分页
   * @params {*}  limit	每页显示记录数	query	true
   * @params {*} page	当前页码，从1开始	query	true
   * @params {*} integer(int32)
   * @params {*}order	排序方式，可选值(asc、desc)	query	false
   * @params {*}orderField	排序字段	query	false
   * @params {*} personId	人员id	query	false
   * @return {*}
   * */
  getApprovePageList: (params) =>
    service.get("/app/datachange/changepage", { params: params }),

  /**
   * @description:   获取继续集中隔离申请的模板
   * @return {*}
   **/
  //  getReportTemp:(params) => service.get("/app/datachange/downloadTemplate",{}),

  /**
   * @description:  获取转运专员列表
   * @params {*}    社区ID
   * @return {*}
   * */
  getTransferList: (params) =>
    service.post("/app/transferList/tsconfirm?community=" + params, {}),

  /**
   * @description:   修改管控人员信息
   * @params {*}    json
   * @return {*}
   **/
  PersonModify: (params) => service.post("/app/modify/", params),

  /**
   * @description:  事务局管控人员分页
   * @params {*}  limit	每页显示记录数	query	true
   * @params {*} page	当前页码，从1开始	query	true
   * @params {*} approveStatus	确认状态（1:待确认,2:已确认）
   * @params {*} community	所属社区
   * @params {*}  controlStatus	管控状态
   * @params {*} controlStep	管控措施 管控措施:集中隔离FocusSegregation,居家隔离InHomeSegregation,健康检测HealthTest,不用隔离NoSegregation
   * @params {*} deviceImei	应装:1 已装:2 待安装:3 其余空
   * @params {*} personName	人员姓名
   * @params {*} personType	人员类型
   * @params {*} status	状态（1:未审核,2:待审核,3:已审核）
   * @return {*}
   * */
  swjPersonPageList: (params) =>service.get("/app/swj/msgpage", { params: params }),
    //待审核变更
  PersonPageList: (params) =>service.get("/app/datachange/page", { params: params }),
//审核申请变更

  datachange: (params) => service.post("/app/datachange/examine", params),
  /**
   * @description:  app 事务局审核
   * @params {*}    json
   * @return {*}
   * */
  swjApprove: (params) => service.post("/app/swj", params),

  /**
   * @description: APP事务局调整人员信息
   * @params {*}    json
   * @return {*}
   * */
  swjPersonUpdate: (params) => service.post("/app/swj/msgupdate", params),

  /**
   * @description:  APP事务局管控人员详情
   * @params {*}    personId	personId
   * @return {*}
   * */
  swjPersonInfo: (params) => service.get("/app/swj/msgdetail/" + params, {}),

  /**
   * @description:  网格信息列表
   * @params {*}    name	 关键字模糊查询
   * @return {*}
   * */
  getGridList: (params) => service.get("/app/gridList", { params: params }),

  /**
   * @description:  登录
   * @params {*}    personId   personId
   * @return {*}
   **/
  getTimeaxis: (params) =>
    service.post("/app/opreateinfo/timeaxis/" + params, {}),

  /**
   * @description: APP事务局调整人员信息
   * @params {*}    json
   * @return {*}
   * */
  ControlTypeAdd: (params) => service.post("/app/ctadd", params),

  /**
   * @description: 获取核酸检测报告
   * @params {*}    json
   * @return {*}
   * */
  getNucleic: (params) =>
    service.get("/app/nucleic/getNucleic", { params: params }),
};
