import { createRouter, createWebHashHistory,createWebHistory } from 'vue-router'
 

const routes = [
  {
    path: '/alllist',
    name: '全部人员列表',
    meta:{
    isFirst:true,
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/alllist.vue')
  },
  {
    path: '/alllist28',
    name: '50类人员列表',
    meta:{
    isFirst:true,
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/alllist28.vue')
  },
  {
    path: '/homelist',
    name: '待填报人员列表',
    component: () => import(/* webpackChunkName: "about" */ '../views/homelist.vue')
  },
  {
    path: '/todolist',
    name: '工作填报',
    meta:{
      isFirst:true,
      },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/todolist.vue')
  },
  {
    path: '/PersonDetial',
    name: '人员详细信息',
    component: () => import(/* webpackChunkName: "about" */ '../views/PersonDetial.vue')
  },
  {
    path: '/changePersonDetial',
    name: '变更人员详细信息',
    component: () => import(/* webpackChunkName: "about" */ '../views/changePersonDetial.vue')
  },
  {
    path: '/homeEdit',
    name: '任务填报',
    component: () => import(/* webpackChunkName: "about" */ '../views/homeEdit.vue')
  },
  {
    path: '/addPersonInfo',
    name: '主动上报',
  
    component: () => import(/* webpackChunkName: "about" */ '../views/addPersonInfo.vue')
  }, 
  {
    path: '/addPersonInfo28',
    name: '50类上报',
  
    component: () => import(/* webpackChunkName: "about" */ '../views/addPersonInfo28.vue')
  }, 
  {
    path: '/addPersonInfo28',
    name: '50类上报',
  
    component: () => import(/* webpackChunkName: "about" */ '../views/addPersonInfo28.vue')
  }, 
  {
    path: '/codeAdd',
    name: '50类人群录入',
    component: () => import(/* webpackChunkName: "about" */ '../views/codeAdd.vue')
  },
   
  {
    path: '/PersonInfoCheck',
    name: '人员信息核查列表',
    meta:{
      isFirst:true,
      },
    component: () => import(/* webpackChunkName: "about" */ '../views/PersonInfoCheck.vue')
  },
  {
    path: '/PersonInfoCheckEdit',
    name: '人员信息核查编辑',
    component: () => import(/* webpackChunkName: "about" */ '../views/PersonInfoCheckEdit.vue')
  },
  {
    path: '/transitList',
    name: '转运单列表',
    meta:{
      isFirst:true,
      },
    component: () => import(/* webpackChunkName: "about" */ '../views/transitList.vue')
  }, 
  {
    path: '/transitEdit',
    name: '转运单填报',
    component: () => import(/* webpackChunkName: "about" */ '../views/transitEdit.vue')
  }, 
  
  {
    path: '/transitDetail',
    name: '转运单详情',
    component: () => import(/* webpackChunkName: "about" */ '../views/transitDetail.vue')
  }, 
  {
    path: '/report',
    name: '管控状态统计',
    component: () => import(/* webpackChunkName: "about" */ '../views/report.vue')
  },   {
    path: '/reportPersonType',
    name: '人员类型统计',
    meta:{
      isFirst:true,
      },
    component: () => import(/* webpackChunkName: "about" */ '../views/reportPersonType.vue')
  }, 
  
  {
    path: '/everySubmit',
    name: '任务填报',
    meta:{
      isFirst:true,
      },
    component: () => import(/* webpackChunkName: "about" */ '../views/everySubmit.vue')
  }, 
  {
    path: '/transitManager',
    name: '转运管理',
    meta:{
      isFirst:true,
      },
    component: () => import(/* webpackChunkName: "about" */ '../views/transitManager.vue')
  }, 
  {
    path: '/reportTransit',
    name: '转运报表',
    meta:{
      isFirst:true,
      },
    component: () => import(/* webpackChunkName: "about" */ '../views/reportTransit.vue')
  },  
  {
    path: '/login',
    name: '演示版登录',
    component: () => import(/* webpackChunkName: "about" */ '../views/login.vue')
  },  

  {
    path: '/swjAllList',
    name: '管控人员列表',
    meta:{
      isFirst:true,
      },
    component: () => import(/* webpackChunkName: "about" */ '../views/swjAllList.vue')
  },  
  {
    path: '/ApproveList',
    name: '审核变更',
    meta:{
      isFirst:true,
      },
    component: () => import(/* webpackChunkName: "about" */ '../views/ApproveList.vue')
  },  
  {
    path: '/swjApproveList',
    name: '待审核人员列表',
    meta:{
      isFirst:true,
      },
    component: () => import(/* webpackChunkName: "about" */ '../views/swjApproveList.vue')
  },  
  {
    path: '/approve',
    name: '人员信息审核',
    component: () => import(/* webpackChunkName: "about" */ '../views/approve.vue')
  },   
  {
    path: '/swjPersonEdit',
    name: '人员信息调整',
    component: () => import(/* webpackChunkName: "about" */ '../views/swjPersonEdit.vue')
  },  
  {
    path: '/blank',
    name: '重点人群管控系统',
    meta:{
      isFirst:true,
      },
    component: () => import(/* webpackChunkName: "about" */ '../views/blank.vue')
  }, 
  {
    path: '/personDetailGuide',
    name: '人员详细信息向导', 
    component: () => import(/* webpackChunkName: "about" */ '../views/personDetailGuide.vue')
  }, 
  {
    path: '/transitListAll',
    name: '全部转运单', 
    component: () => import(/* webpackChunkName: "about" */ '../views/transitListAll.vue')
  },  
]

const router = createRouter({
  history:createWebHashHistory(),//createWebHistory(),  
  routes
})
// 全局前置守卫，设置页面标题
router.beforeEach((to, from, next) => {
  // to and from are both route objects. must call `next`.
  // document.title = to.meta.title;
 // document.title = to.matched[0].name;
 document.title = to.matched[0]==null?'重点人员管控系统': to.matched[0].name;
 
 // 前进  
 if(to.path=='/alllist'&&(from.path=='/report'||from.path=='/reportPersonType'))
 {
   to.params.isrefresh=true  
 }else 
 {
  to.params.isrefresh=false   
 }
  if(to.meta.isFirst)
  {
    window.localStorage.setItem('isFirst',true) 
  }else 
  {
    window.localStorage.setItem('isFirst',false)  
  }
  if (to.path === '/blank')  next()
  if(from.query.backhome=="list"){
    next({path:'/blank'})
  }else{
    next(); //必须写
  }
 
});

// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location, onResolve, onReject) {
//   if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
//   return originalPush.call(this, location).catch(err => err)
// } 

export default router
