import { createStore } from 'vuex'

export default createStore({
  state: {
     Dict:[],
     timestamp:0,
     isFirst:false,
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
